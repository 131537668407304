<template>
    <el-dialog
            :title="title"
            :visible.sync="createDialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :append-to-body="true"
            v-drag-dialog
            @close="close">
        <div class="pageContainer">
            <el-form label-position="right"
                     :model="formData"
                     :rules="productDetailRules"
                     ref="ProductForm"
                     label-width="100px"
                     element-loading-background="rgba(0, 0, 0, 0.8)">
              <el-form-item label="商品细节图片" prop="image">
                <img :src="formData.image" @click="showImg(formData.image, '商品细节')" class="smallImg" alt="">
                <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadCover"></file-upload-btn>
              </el-form-item>
            </el-form>
            <el-row type="flex" justify="center">
                <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
  import { productDetailRules, resetProductDetail, execute } from '../option'

  export default {
    name: 'addOrEditProduct',
    components: {
      FileUploadBtn
    },
    data() {
      return {
        optType: 'add',
        createDialogVisible: false,
        callback: null,
        formData: resetProductDetail(),
        productDetailRules,
        addingLoading: false,
        supplierList: []
      }
    },
    computed: {
      title() {
        return this.optType === 'add' ? '新增商品细节' : '编辑商品细节'
      }
    },
    methods: {
      ...mapActions(['saveProductDetail', 'getProductDetailById', 'updateProductDetail']),
      show(options) {
        this.optType = options.optType
        this.callback = options.callback
        if (this.optType === 'edit') {
          this.tryGetProductById(options.detail.id)
          // this.formData = options.detail
        } else {
          this.formData = resetProductDetail()
        }
        this.formData.productId = options.detail.id
        this.createDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ProductForm.clearValidate()
        })
      },
      close() {
        this.createDialogVisible = false
      },
      showImg(imgUrl, title) {
        execute('showImg', {
          imgUrl,
          title
        })
      },
      handleUploadCover(url) {
        console.log(url)
        this.formData.image = url
      },
      tryGetProductById(id) {
        this.getProductDetailById(id).then(res => {
          this.formData = res || this.formData
        })
      },
      tryUpdateProductDetail() {
        this.addingLoading = true
        this.updateProductDetail(this.formData).then(() => {
          this.$message.success('操作成功')
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      trySubmit() {
        if (this.addingLoading) {
          return
        }
        this.$refs.ProductForm.validate((valid) => {
          if (valid) {
            const api = this.optType === 'add' ? this.trySaveProductDetail : this.tryUpdateProductDetail
            api()
          }
        })
      },
      trySaveProductDetail() {
        this.addingLoading = true
        this.saveProductDetail(this.formData).then(() => {
          this.$message.success('提交成功')
          this.resetProductDetail()
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      resetProductDetail() {
        this.formData = resetProductDetail()
      }
    }
  }
</script>
<style lang="scss">
    .numberInput {
        width: 100%;

        .el-input {
            .el-input__inner {
                text-align: left;
            }
        }
    }
</style>
<style scoped lang="scss">
    @import "../../../scss/elementVar";

    .pageContainer {
        padding: 20px;
    }

    .tagWrap {
        margin-bottom: 20px;
    }

    .uploadBtn {
        padding: 30px 0;
        width: 150px;
        height: 100px;
        box-sizing: border-box;
        text-align: center;
        color: $--color-info;
        border: 2px dashed $--color-info;
        opacity: 0.7;
        border-radius: 4px;
        transition: all linear 100ms;
    }

    .uploadBtn:hover {
        opacity: 1;
    }

    .uploadBtnIcon {
        font-weight: bold;
        font-size: 30px;
    }

    .uploadBtnText {
        line-height: 10px;
        font-size: 14px;
    }

    .imgContainer {
        float: left;
        margin: 0 10px 10px 0;
    }

    .smallImg {
        width: 40px;
    }
    .widthCover{
        width: 100%;
    }
</style>
